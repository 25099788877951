<template>
	<div>
		<b-card>
			<div v-for="v in baseInf" class="w-1/2 inline-block pr-4">
				<p class="my-2">{{ v.title }}</p>
				<input class="form-control -mt-1.5" v-model="input[v.key]" :placeholder="v.title" type="text" />
			</div>

			<div class="w-1/2 inline-block pr-4">
				<p class="mt-2 mb-0.5">정산 은행</p>
				<vSelect v-model="input.bankOpt" :clearable="false" :options="bankOpts" />
			</div>

			<div v-for="v in bankInf" class="w-1/2 inline-block pr-4">
				<p class="my-2">{{ v.title }}</p>
				<input class="form-control -mt-1.5" v-model="input[v.key]" :placeholder="v.title" type="text" />
			</div>

			<div class="">
				<p class="mt-2 mb-0.5">계약일</p>
				<datePicker model="input.contractDate" />
			</div>

			<div v-for="v in picInf" class="w-1/2 inline-block mt-8">
				<div class="mr-4 pb-4 px-4 picBorder">
					<div
						v-for="k in [
							{ key: 'Grade', title: '직급' },
							{ key: 'Name', title: '이름' },
							{ key: 'Email', title: '이메일' },
							{ key: 'Phone1', title: '연락처1' },
							{ key: 'Phone2', title: '연락처2' },
						]"
					>
						<p class="my-2">{{ v.prefix }} 담당자 {{ k.title }}</p>
						<input
							class="form-control -mt-1.5"
							v-model="input[v.key + 'Pic' + k.key]"
							:placeholder="v.prefix + ' 담당자 ' + k.title"
							type="text"
						/>
					</div>
				</div>
			</div>

			<b-button
				class="w-40 my-8 block mx-auto"
				@click="addPartner"
				v-text="'파트너사 ' + (isEdit ? '수정' : '등록')"
			></b-button>
		</b-card>
	</div>
</template>

<script>
import { postApi, putApi } from 'libs/axios'

export default {
	data() {
		return {
			isEdit: false,
			storeSeq: this.$route.params.storeSeq * 1,

			baseInf: [
				{
					key: 'comName',
					title: '회사명',
				},
				{
					key: 'comSsn',
					title: '사업자등록번호',
				},
				{
					key: 'objectJoin',
					title: '사업형태 ,로 구분',
				},
				{
					key: 'comCeo',
					title: '입점사 대표명',
				},
				{
					key: 'comPhone',
					title: '대표전화',
				},
				{
					key: 'contractPicName',
					title: '계약자명',
				},
				{
					key: 'comAddress',
					title: '주소',
				},
				{
					key: 'website',
					title: '웹사이트',
				},
			],

			bankInf: [
				{ key: 'bankAccount', title: '정산은행계좌' },
				{ key: 'bankOwner', title: '정산계좌주' },
				{ key: 'taxEmail', title: '세금계산서발행용이메일' },
			],

			picInf: [
				{
					key: 'hott',
					prefix: '총괄',
				},
				{
					key: 'po',
					prefix: '발주',
				},
				{
					key: 'cs',
					prefix: 'CS',
				},
				{
					key: 'settlement',
					prefix: '정산',
				},
			],

			bankOpts: [],
			input: {
				comName: '',
				comSsn: '',
				objectJoin: '',
				comCeo: '',
				comPhone: '',
				contractPicName: '',
				comAddress: '',
				website: '',

				bankOpt: {},
				bankAccount: '',
				bankOwner: '',
				taxEmail: '',

				contractDate: new Date(),

				hottPicGrade: '',
				hottPicName: '',
				hottPicEmail: '',
				hottPicPhone1: '',
				hottPicPhone2: '',

				poPicGrade: '',
				poPicName: '',
				poPicEmail: '',
				poPicPhone1: '',
				poPicPhone2: '',

				csPicGrade: '',
				csPicName: '',
				csPicEmail: '',
				csPicPhone1: '',
				csPicPhone2: '',

				settlementPicGrade: '',
				settlementPicName: '',
				settlementPicEmail: '',
				settlementPicPhone1: '',
				settlementPicPhone2: '',
			},
		}
	},
	methods: {
		addPartner() {
			const inp = this.input
			let isEmpty = false,
				isNormalMail = true

			for (let k in inp) if (!inp[k]) isEmpty = true
			if (isEmpty)
				return alert.w(
					'입력되지 않았거나 잘못 입력된 항목이 있습니다\r\n전부 입력해주세요\r\n사업자등록번호, 정산은행계좌 및 연락처에는 숫자만 입력할 수 있습니다'
				)
				//isEmail()
			;['taxEmail', 'hottPicEmail', 'poPicEmail', 'csPicEmail', 'settlementPicEmail'].forEach(k => {
				if (!isEmail(inp[k])) isNormalMail = false
			})
			if (!isNormalMail)
				return alert.w('비정상적인 이메일 형식이 포함되어 있습니다\r\n이메일 형식을 확인해주세요')

			if (!inp.bankOpt.label) return alert.w('정산은행을 선택해주세요')

			const data = { ...inp }
			data.bank = data.bankOpt.label
			delete data.bankOpt

			data.contractDate = data.contractDate.get_time('d')
			data.saveStatus = 'Y'

			if (!this.isEdit)
				postApi('api/backoffice/partnerHandle/insertPartner', data).then(() => {
					alert.s('파트너사 등록에 성공했습니다')
				})
			else {
				data.seq = this.storeSeq
				putApi('api/backoffice/partnerHandle/updatePartnerInfo', data).then(() => {
					alert.s('파트너사 수정에 성공했습니다')
				})
			}
		},
	},
	created() {
		getConfig('bankcd', false, false).then(({ bankcd }) => {
			this.bankOpts = bankcd

			if (this.storeSeq) {
				this.isEdit = true
				layout.pageTitle = '파트너사 수정'
				postApi('/partnerSelect/partnerDetail', { storeSeq: this.storeSeq }).then(res => {
					const inp = this.input
					let { bankInfo, basicInfo, contractInfo, picInfo } = res
					inp.bankOpt = getOptsFromLabel(this.bankOpts, bankInfo.bank)
					inp.bankAccount = bankInfo.bankAccount
					inp.bankOwner = bankInfo.bankOwner
					inp.taxEmail = bankInfo.texEmail

					inp.comName = res.companyName

					for (let k in basicInfo) inp[k] = basicInfo[k]

					inp.contractPicName = contractInfo.contractPicName
					inp.contractDate = new Date(contractInfo.contractDate)

					for (let k in picInfo) inp[k] = picInfo[k]
				})
			}
		})

		//this.search()
	},
}
</script>

<style>
.picBorder {
	border: 1px solid gray;
	border-radius: 10px;
}
</style>
